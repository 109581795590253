import React,{useState,useEffect} from "react";
import {Nav, Navbar, Alert, Carousel, Form, CardColumns, Card, Spinner} from "react-bootstrap";
import uovo from './uovo.png';
import oca from './oca.jpg';
import retroGiallo from './retro giallo.jpg';
import retroArancione from './retro arancionepng.jpg';
import retroAzzurro from './retro azzurro.jpg'
import bg from './oca_ritaglio.jpg';
import background_app from './background_app.jpg';
import freccia from './arrow.png';
import frecciasx from './frecciasx.png';
import Axios from 'axios';
import './App.css';
import ReactCardFlip from "react-card-flip";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";

function App() {
    const [testo,setTesto] = useState("");
    const[button,setButton] = useState(0);
    const [listaTesti,setListaTesti] = useState([]);
    let [flag,setFlag] = useState(0);
    let [lan,setLan] = useState("it");
    let pressed = 0;
    let [show,setShow] = useState(false);
    let [show2,setShow2] = useState(false);
    let [show3,setShow3] = useState(false);
    let [showSpinner, setShowSpinner] = useState(false);
    let [showSpinner2, setShowSpinner2] = useState(false);
    let [showSpinner3, setShowSpinner3] = useState(false);
    let [showSpunta, setShowSpunta] = useState(false);
    let [showSpunta2, setShowSpunta2] = useState(false);
    let [showSpunta3, setShowSpunta3] = useState(false);
    let [loading, setLoading] = useState(false);
    let height = window.screen.availHeight;
    let send = 0;

    const refreshPostcards = () => {
        Axios.get("https://carlo-server-2.herokuapp.com/api/getPrimi8"/*"http://localhost:3001/api/get"*/).then((response) =>
            setListaTesti(response.data))
    }

    useEffect(
        () => {
                setLoading(true);
            Axios.get("https://carlo-server-2.herokuapp.com/api/getPrimi8"/*"http://localhost:3001/api/get"*/).then((response) =>
                setListaTesti(response.data))
        } , []
    )

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1800)
    }, []);

    const submitTestoGiallo = () => {

        if(testo.trim()===""){
            setShow(true);
        }
        else{
            document.getElementById("invia1").setAttribute("href","#Postcards");


            setShowSpinner(true);
            setFlag(1);
        Axios.post("https://carlo-server-2.herokuapp.com/api/insert",
            {testo : testo, colore : "Giallo"}).then( () => alert("Successful insert!"));
            Axios.post("https://yournotes-app.herokuapp.com/api/insert",
                {testo : testo, colore : "Giallo"}).then( () => alert("Successful insert!"));


        }
    }



    const submitTestoArancione = () => {

        if(testo===""){
            setShow2(true);


        }
        else if(testo!=="") {

            document.getElementById("invia2").setAttribute("href","#Postcards");

            setShowSpinner2(true);

            Axios.post("https://carlo-server-2.herokuapp.com/api/insert"/*"http://localhost:3001/api/insert"*/,
                {testo: testo, colore: "Arancione"}).then(() => alert("Successful insert!"));
            Axios.post("https://yournotes-app.herokuapp.com/api/insert"/*"http://localhost:3001/api/insert"*/,
                {testo: testo, colore: "Arancione"}).then(() => alert("Successful insert!"));

            setFlag(1);


        }

    }

    const submitTestoAzzurro = () => {
        if(testo.trim()===""){
            setShow3(true);
        }
        else {
            setShow3(false);
            setShowSpinner3(true);
            Axios.post("https://carlo-server-2.herokuapp.com/api/insert"/*"http://localhost:3001/api/insert"*/,
                {testo: testo, colore: "Azzurro"}).then(() => alert("Successful insert!"));
            Axios.post("https://yournotes-app.herokuapp.com/api/insert"/*"http://localhost:3001/api/insert"*/,
                {testo: testo, colore: "Azzurro"}).then(() => alert("Successful insert!"));
            setFlag(1);
            setButton(1);
            document.getElementById("invia3").setAttribute("href", "#Postcards");
        }
    }

    const sendMessage = () => {
        if(testo.trim()===""){
            return true;
        }
        else{
            return false;
        }
    }


    const refresh = () => setTimeout(function() {
            if(flag==1) {

                window.location.href = "#Postcards";
                Axios.get("https://carlo-server-2.herokuapp.com/api/getPrimi8"/*"http://localhost:3001/api/get"*/).then((response) =>
                    setListaTesti(response.data));
                setFlag(0);
                document.getElementById("form1").value = "";
                document.getElementById("form2").value = "";
                document.getElementById("form3").value = "";
                setIsFlipped(false);
                setIsFlipped2(false);
                setIsFlipped3(false);
                setShowSpinner(false);
                setShowSpinner2(false);
                setShowSpinner3(false);
                setShowSpunta(true);
                setShowSpunta2(true);
                setShowSpunta3(true);
                setTesto("");
                document.getElementById("invia1").removeAttribute("href");
                document.getElementById("invia2").removeAttribute("href");
                document.getElementById("invia3").removeAttribute("href");
            }
        }, 1000)


    const [isFlipped, setIsFlipped] = useState(false);
    const handleClick = () => {
        setIsFlipped(!isFlipped);
    };

    const [isFlipped2, setIsFlipped2] = useState(false);
    const handleClick2 = () => {
        setIsFlipped2(!isFlipped2);
    };

    const [isFlipped3, setIsFlipped3] = useState(false);
    const handleClick3 = () => {
        setIsFlipped3(!isFlipped3);
    };


    const itaEng = () => {
        if(pressed===0) {
            setLan("it");
            document.getElementById("Intro").innerHTML =
                '“L’uovo primordiale esplose in frammenti ' + "<br>" +
                'che diedero vita alle diverse parti del mondo:' + "<br>" +
                'il tuorlo mutò nella terra,' + "<br>" +
                'l’albume nelle acque,' + "<br>" +
                'e il guscio dell’uovo diede vita alla sfera celeste,' + "<br>" +
                'che era piena di stelle.”';
            document.getElementById("Mail").innerHTML =
                'Una storia non ha luogo di nascita,' + "<br>" +
                'ho letto una volta in un libro di fiabe estoni,' + "<br>" +
                'può viaggiare da persona a persona, da una lingua a un’altra.' + "<br>" + "<br>" +
                'Ne ricordi una?' + "<br>" + "<br>" +
                'Clicca sulle cartoline per scegliere il francobollo che più ti piace,' + "<br>" +
                'pensa a un ricordo, alla parte di una storia' + "<br>" +
                'da condividere e custodire.' + "<br>" + "<br>" +
                'Spedisci la cartolina scelta e continua a raccontare.';
            //document.getElementById("itaeng").innerHTML = 'ITA';
            document.getElementById("formLabel1").innerHTML = 'Scrivi la tua cartolina:';
            document.getElementById("formLabel2").innerHTML = 'Scrivi la tua cartolina:';
            document.getElementById("formLabel3").innerHTML = 'Scrivi la tua cartolina:';
            document.getElementById("itaeng").setAttribute("width","70px");
            document.getElementById("itaeng").setAttribute("height","65px");
            document.getElementById("invia1").innerHTML = "INVIA";
            document.getElementById("invia2").innerHTML = "INVIA";
            document.getElementById("invia3").innerHTML = "INVIA";
            document.getElementById("navIntroId").innerHTML = "Intro";
            document.getElementById("navPostcardsId").innerHTML = "Cartoline";
            document.getElementById("navMailId").innerHTML = "Spedisci";
            show===true?document.getElementById("Alert1").innerHTML = "Inserire un testo!":document.getElementById("navIntroId");
            show2===true?document.getElementById("Alert2").innerHTML = "Inserire un testo!":document.getElementById("navIntroId");
            show3===true?document.getElementById("Alert3").innerHTML = "Inserire un testo!":document.getElementById("navIntroId");
            pressed = 1;
        }
        else if(pressed===1){
            setLan("en");
            document.getElementById("Intro").innerHTML =
                '“The primordial egg exploded into fragments ' + "<br>" +
                'which gave birth to different parts of the world:' + "<br>" +
                'the egg yolk turned into the earth,' + "<br>" +
                'the egg white turned into waters,' + "<br>" +
                'and the egg shell gave birth to the heavenly sphere,' + "<br>" +
                ' which was full of stars."';
            document.getElementById("Mail").innerHTML =
                ' A story has no place of birth,' + "<br>" +
                '                  I read once in an Estonian Fairy Tale book.' + "<br>" +
                '                  It can wander from person to person,' + "<br>" +
                '                  from one language to another.' + "<br>" + "<br>" +
                '                  Do you remember one?' + "<br>" + "<br>" +
                '                  Click on the postcards to choose the stamp you prefer,' + "<br>" +
                '                  think about a memory, a part of a story' + "<br>" +
                '                  to share and preserve.' + "<br>" + "<br>" +
                '                  Mail the postcard of your choice and keep telling.';
            //document.getElementById("itaeng").innerHTML = 'ENG';
            document.getElementById("formLabel1").innerHTML = 'Write your postcard:';
            document.getElementById("formLabel2").innerHTML = 'Write your postcard:';
            document.getElementById("formLabel3").innerHTML = 'Write your postcard:';
            document.getElementById("itaeng").setAttribute("width","100px");
            document.getElementById("itaeng").setAttribute("height","65px");
            document.getElementById("invia1").innerHTML = "SEND";
            document.getElementById("invia2").innerHTML = "SEND";
            document.getElementById("invia3").innerHTML = "SEND";
            document.getElementById("navIntroId").innerHTML = "Intro";
            document.getElementById("navPostcardsId").innerHTML = "Postcards";
            document.getElementById("navMailId").innerHTML = "Mail it.";
            show===true?document.getElementById("Alert1").innerHTML = "Insert a text!":document.getElementById("navIntroId");
            show2===true?document.getElementById("Alert2").innerHTML = "Insert a text!":document.getElementById("navIntroId");
            show3===true?document.getElementById("Alert3").innerHTML = "Insert a text!":document.getElementById("navIntroId");
            pressed=0;
        }

    }

    const spinnerGiallo = () => {
        return(
            <div style={{marginBottom:'80em'}}>
                <Spinner animation="grow" variant="warning"/>
                <p>Loading...</p>
            </div>
        );
    }

    const spinnerRosso = () => {
        return(
            <div style={{marginBottom:'80em'}}>
                <Spinner animation="grow" variant="danger"/>
                <p>Loading...</p>
            </div>
        );
    }

    const spinnerAzzurro = () => {
        return(
            <div style={{marginBottom:'80em'}}>
                <Spinner animation="grow" variant="info"/>
                <p>Loading...</p>
            </div>
        );
    }

    const spinnerUovo = () => {
        return(
            <div style={{marginBottom:'80em'}}>
                <img src={uovo} className="App-logo-spinner-sm" alt="logo"/>
                <p>Sending...</p>
            </div>
        );
    }


    const spuntaVerde = () => {
        return(
            <div>
                <img  width="46em"/>
                <p>Send Correctly</p>
            </div>
        );

    }

  return (

    <div className="App" id={"Home"} style={{ backgroundImage: `url(${background_app})`}}>
        {loading?
            <div className={"spinnerContainer"} style={{height:`${height}px`,display: "flex"}} >
            <img src={uovo} className="App-logo-spinner" alt="logo"/>
            </div> :
            <header class={"container"} >
                <Navbar collapseOnSelect expand="lg" bg="light" variant="light" fixed="top" style={{opacity: '0.6',position:"sticky"}} >

                    <Navbar.Collapse id="responsive-navbar-nav" className="d-flex flex-row justify-content-between">
                        <Nav className={"navIntro"} onClick={refreshPostcards}>
                            <Nav.Link id={"navIntroId"}
                                      onClick={() => {setShowSpunta(false); setShowSpunta2(false); setShowSpunta3(false)}}
                                      style={{fontSize: "22px", color: "#000", textShadow: '2px 2px 0px white'}}
                                      href="#Home">Intro</Nav.Link>
                        </Nav>
                        <Nav id={"navMail"} onClick={refreshPostcards}>
                            <Nav.Link id={"navMailId"}
                                      onClick={() => {setShowSpunta(false); setShowSpunta2(false); setShowSpunta3(false)}}
                                      style={{fontSize: "22px", color: "#000", textShadow: '2px 2px 0px white'}}
                                      href="#Mail it.">Mail it.</Nav.Link>
                        </Nav>
                        <Nav className={"navPostcards"} onClick={refreshPostcards}>
                            <Nav.Link id={"navPostcardsId"}
                                      onClick={() => {setShowSpunta(false); setShowSpunta2(false); setShowSpunta3(false)}}
                                      style={{fontSize: "22px", color: "#000", textShadow: '2px 2px 0px white'}}
                                      href="#Postcards">Postcards</Nav.Link>
                        </Nav>
                        {window.screen.availWidth < 1000 ? <Nav><Nav.Link id={"itaeng"} onClick={itaEng} style={{
                            color: "#777",
                            borderRadius: "2em",
                            width: '2em'
                        }}>IT|EN</Nav.Link></Nav> : null}
                    </Navbar.Collapse>
                    {window.screen.availWidth >= 1000 ? <Nav><Nav.Link id={"itaeng"} onClick={itaEng} style={{
                        color: "#777",
                        borderRadius: "2em",
                        width: '2em',
                        marginRight: '1em'
                    }}>IT|EN</Nav.Link></Nav> : null}

                </Navbar>

                <div className="d-flex flex-column justify-content-center"
                     style={{backgroundColor: 'rgba(200,200,200,0.7)'}} id={"parati1"} >

                    <div >
                        <img src={uovo} className="App-logo" alt="logo" style={{opacity: '1'}}/>
                        <p class="col-lg-12 col-md-8 col-sm-12 p1" style={{color: "#ffffff"}} id={"Intro"}>
                            “The primordial egg exploded into fragments <br></br>
                            which gave birth to different parts of the world: <br></br>
                            the egg yolk turned into the earth, <br></br>
                            the egg white turned into waters, <br></br>
                            and the egg shell gave birth to the heavenly sphere, <br></br>
                            which was full of stars."
                        </p>
                        <a href={"#Section2"} className={"freccia"}> <img src={freccia} style={{width: '3em'}}/> </a>

                    </div>

                    <div style={{height: "30em"}}></div>
                    <div id={"Section2"}>
                    </div>
                    <div className={"p2"}>
                        <div className="d-flex justify-content-center">

                            <p class="col-lg-12 col-md-8 col-sm-12 p2" style={{color: "#ffffff"}} id={"Mail"}>
                                A story has no place of birth,<br></br>
                                I read once in an Estonian Fairy Tale book. <br></br>
                                It can wander from person to person,<br></br>
                                from one language to another.<br></br>
                                <br></br>
                                Do you remember one?<br></br>
                                <br></br>
                                Click on the postcards to choose the stamp you prefer,<br></br>
                                think about a memory, a part of a story<br></br>
                                to share and preserve.<br></br>
                                <br></br>
                                Mail the postcard of your choice and keep telling.
                            </p>

                        </div>
                    </div>
                    <a href={"#Mail it."} style={{borderRadius: "20px", color: 'white'}}> <img src={freccia} style={{
                        width: '3em',
                        marginBottom: '12em'
                    }}/> </a>
                    <div style={{height: "30em"}}></div>
                    <div id={"Mail it."} className={"container"} style={{overflowY: "scroll", height: `${height}px`}}>

                        <div>
                            <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
                                <div>
                                    <a><img style={{marginTop: "8em"}} src={oca}
                                            className="col-lg-6 col-md-8 col-sm-12 fronteOca"
                                            onClick={handleClick}/></a>
                                </div>

                                <div className="col-lg-6 mr-auto ml-auto" style={{marginTop: "8em"}}>

                                    <Card className="mr-auto ml-auto" id={"cardGialla"}>
                                        <Card.Img src={retroGiallo}/>
                                        <Card.ImgOverlay>
                                            <div className="d-flex flex-column justify-content-center">
                                                <Form>
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Label id="formLabel1" className={"formLabel"}>Write your
                                                            postcard: </Form.Label>

                                                        <Form.Control style={{fontSize: "18px"}}
                                                                      id={"form1"}
                                                                      className={"formControl"} as="textarea"
                                                                      rows={window.screen.availWidth >= 1500 ? 4 : 2}
                                                                      name={"testo"}
                                                                      onChange={(e) => {setShow(false); setTesto(e.target.value);}}
                                                        />
                                                        <div style={{marginTop: "0.3em"}}
                                                             className="d-flex justify-content-between">
                                                            <a id={"chiudi1"} className={"chiudi"} onClick={handleClick}
                                                               style={{color: "red"}} href={"#Mail it."}>
                                                                <img src={frecciasx}
                                                                     style={{width: '1em', opacity: '0.7'}}/>
                                                            </a>
                                                            <a id={"invia1"} className={"invia"}
                                                               onClick={submitTestoGiallo} style={{color: "#777"}}>
                                                                SEND
                                                            </a>

                                                        </div>
                                                    </Form.Group>
                                                </Form>
                                            </div>
                                        </Card.ImgOverlay>
                                    </Card>
                                </div>
                            </ReactCardFlip>
                            <div className="d-flex flex-row justify-content-center">
                                {showSpinner === true ? spinnerUovo() : null}
                                <Alert variant="danger" onClose={() => setShow(false)} dismissible show={show}
                                       style={{marginTop: '1em', width: '13em', height: '2.5em'}}>
                                    <Alert.Heading id={"Alert1"} style={{fontSize:'16px'}}>Insert a text!</Alert.Heading>
                                </Alert>
                            </div>
                            <ReactCardFlip isFlipped={isFlipped2} flipDirection="horizontal">
                                <div>
                                    <a><img src={oca} className="col-lg-6 col-md-8 col-sm-12" onClick={handleClick2}
                                            style={{padding: '1em'}}/></a>
                                </div>


                                <div id={"retroArancione"} style={{paddingTop: '1em', paddingBottom: '1em'}}
                                     className="col-lg-6 mr-auto ml-auto">
                                    <Card style={{}}>
                                        <Card.Img src={retroArancione}/>
                                        <Card.ImgOverlay>

                                            <Form>
                                                <Form.Group>
                                                    <Form.Label id="formLabel2" className={"formLabel"}>Write your
                                                        postcard: </Form.Label>
                                                    <Form.Control style={{fontSize: "18px", outline: 'none'}}
                                                                  id={"form2"}
                                                                  className={"formControl"} as="textarea"
                                                                  rows={window.screen.availWidth >= 1500 ? 4 : 2}
                                                                  name={"testo"}
                                                                  onChange={(e) => {setShow2(false); setTesto(e.target.value);}}/>

                                                    <div style={{marginTop: "0.3em"}}
                                                         className="d-flex justify-content-between">
                                                        <a id={"chiudi2"} className={"chiudi"} onClick={handleClick2}
                                                           style={{color: "red"}} href={"#Mail it."}>
                                                            <img src={frecciasx}
                                                                 style={{width: '1em', opacity: '0.7'}}/>
                                                        </a>
                                                        <a id={"invia2"} className={"invia"}
                                                           onClick={submitTestoArancione} style={{color: "#777"}}>
                                                            SEND
                                                        </a>
                                                    </div>
                                                </Form.Group>
                                            </Form>

                                        </Card.ImgOverlay>
                                    </Card>
                                </div>
                            </ReactCardFlip>
                            <div className="d-flex flex-row justify-content-center">
                                {showSpinner2 === true ? spinnerUovo() : null}
                                <Alert variant="danger" onClose={() => setShow2(false)} dismissible show={show2}
                                       style={{width: '13em', height: '2.5em'}}>
                                    <Alert.Heading id={"Alert2"} style={{fontSize:'16px'}}>Insert a text!</Alert.Heading>
                                </Alert>
                            </div>
                            <ReactCardFlip isFlipped={isFlipped3} flipDirection="horizontal">
                                <div>
                                    <a><img src={oca} className="col-lg-6 col-md-8 col-sm-12"
                                            onClick={handleClick3}/></a>
                                </div>

                                <div id={"retroAzzurro"} style={{}} className="col-lg-6 mr-auto ml-auto">
                                    <Card>
                                        <Card.Img src={retroAzzurro}/>
                                        <Card.ImgOverlay>
                                            <div className="d-flex flex-column justify-content-center">
                                                <Form>
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Label id="formLabel3" className={"formLabel"}>Write your
                                                            postcard: </Form.Label>
                                                        <Form.Control style={{fontSize: "18px"}}
                                                                      id={"form3"}
                                                                      className={"formControl"} as="textarea"
                                                                      rows={window.screen.availWidth >= 1500 ? 4 : 2}
                                                                      name={"testo"}
                                                                      onChange={(e) => {setShow3(false); setTesto(e.target.value);}}/>
                                                        <div style={{marginTop: "0.3em"}}
                                                             className="d-flex justify-content-between">
                                                            <a id={"chiudi3"} className={"chiudi"}
                                                               onClick={handleClick3} style={{color: "red"}}
                                                               href={"#Mail it."}>
                                                                <img src={frecciasx}
                                                                     style={{width: '1em', opacity: '0.7'}}/>
                                                            </a>
                                                            <a id={"invia3"} className={"invia"}
                                                               onClick={submitTestoAzzurro} style={{color: "#777"}}
                                                               disabled={sendMessage()}>
                                                                SEND
                                                            </a>

                                                        </div>
                                                    </Form.Group>
                                                </Form>
                                            </div>
                                        </Card.ImgOverlay>
                                    </Card>
                                </div>

                            </ReactCardFlip>
                            <div className="d-flex flex-row justify-content-center">
                                {showSpinner3 === true ? spinnerUovo() : null}
                                <Alert variant="danger" onClose={() => setShow3(false)} dismissible show={show3}
                                       style={{marginTop: '1em', marginBottom: "6em", width: '13em', height: '2.5em'}}>
                                    <Alert.Heading id={"Alert3"} style={{fontSize:'16px'}}>Insert a text!</Alert.Heading>
                                </Alert>
                            </div>
                            <a href={"#Postcards"} onClick={() => {setShowSpunta(false); setShowSpunta2(false); setShowSpunta3(false)}} style={{borderRadius: "20px", color: 'white'}}> <img src={freccia} style={{
                                width: '3em',
                                marginTop:'10em'
                            }}/> </a>
                            <div style={{height: "20em"}}></div>
                        </div>

                    </div>

                </div>
                <div style={{height: "80em", backgroundColor: 'rgba(200,200,200,0.7)'}}></div>

                <div id={"Postcards"} class="container" style={{
                    backgroundSize: "cover",
                    marginTop: '20em',
                    backgroundImage: `url(${bg})`,
                    backgroundRepeat: "no-repeat",
                    overflowY: "scroll",
                    height: "800px"
                }}>

                    <div className={"Cartoline"} style={{marginBottom: "18em"}}>

                        <div className="row">
                            {

                                listaTesti.map((val) => {

                                    refresh();
                                    return (
                                        <div class="col-lg-6 col-md-8 col-sm-12" style={{padding: '1em'}}>
                                            <Card style={{margin: "5px"}} className={"card"}>

                                                <div className="card text-center">
                                                    <Card.Body>
                                                        {val.colore === "Giallo" ?
                                                            <Card.Img class="card-img-top" src={retroGiallo}
                                                                      className={"cardImg"}/> :
                                                            val.colore === "Arancione" ?
                                                                <Card.Img class="card-img-top" src={retroArancione}
                                                                          className={"cardImg"}/> :
                                                                val.colore === "Azzurro" ?
                                                                    <Card.Img class="card-img-top" src={retroAzzurro}
                                                                              className={"cardImg"}/> : null}

                                                        {window.screen.availWidth >= 1500 ?
                                                            <Card.ImgOverlay className={"cardOverlay"}>
                                                                <Card.Text
                                                                    style={{fontSize: "16px", position: "relative"}}
                                                                    class="col-lg-9 col-md-6 col-sm-8 col-9">
                                                                    {val.testi}
                                                                </Card.Text>
                                                            </Card.ImgOverlay> :
                                                            <Card.ImgOverlay>
                                                                <Card.Text style={{
                                                                    fontSize: "16px",
                                                                    position: "relative",
                                                                    overflowY: "auto"
                                                                }}
                                                                           class="col-lg-9 col-md-6 col-sm-9 col-9">
                                                                    {val.testi}
                                                                </Card.Text>
                                                            </Card.ImgOverlay>}
                                                    </Card.Body>
                                                </div>

                                            </Card>
                                        </div>

                                    )
                                })

                            }

                        </div>
                    </div>

                </div>

            </header>
        }
    </div>


  );
}

export default App;
